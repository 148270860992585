import React from "react";

import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Header = ({ allImages, related_service, area }) => {
	const images = getImage(allImages.childImageSharp);

	return (
		<>
			<div className="relative h-32 md:h-64  overflow-hidden  ">
				<div className="absolute inset-0 z-10 bg-slate-800 text-center flex flex-col  justify-center bg-opacity-50 hover:bg-opacity-80 duration-700">
					<h1 className=" text-white text-3xl hover:underline drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
						{area ? `${related_service} in ${area}` : related_service}{" "}
					</h1>
				</div>
				<div className=" flex flex-wrap content-center">
					<GatsbyImage
						image={images}
						className="mx-auto "
						placeholder="blurred"
						alt={area ? `${related_service} in ${area}` : related_service}
						style={{ height: "100%", width: "100%" }}
					/>
				</div>
			</div>
		</>
	);
};

export default Header;
