import React from "react";
import { Link } from "gatsby";
var slugify = require("slugify");

function titleCase(str) {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
}

const RelatedQA = ({ RelatedService, data }) => {
	// const datas = useStaticQuery(graphql`
	// 	query MyQuery($RelatedService: String) {
	// 		allFaqsCsv(
	// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
	// 		) {
	// 			distinct(field: { Search: SELECT })
	// 		}
	// 	}
	// `);
	return (
		<>
			{data.distinct && data.distinct.length > 0 ? (
				<section className="mt-20 mx-auto  max-w-screen-xl items-center mb-20 justify-between px-4">
					<h3 className="text-3xl mb-10  text-blue-500 text-center pb-4  border-b-2">
						{titleCase(RelatedService)} Related Questions
					</h3>
					<div className=" grid grid-cols-1 md:grid-cols-1 gap-6 h-80 rounded-md overflow-auto outline outline-offset-[4px] hover:outline-offset-[0px]  outline-slate-300/10 hover:outline-slate-800/40 duration-300">
						<div className="col-span-1  flex md:col-span-14 bg-gray-100 p-4 ">
							<div className="pl-0 p-2 pr-0 lg:pl-6 lg:pr-2  my-auto  w-full   ">
								{/* <ul className="text-2xl text-blue-500 mb-4">
								{data?.distinct?.map((item) => {
									return (
										<li className="mb-2 divide-y">
											<Link
												to={`/faq/${slugify(item, {
													lower: false,
												})}`}
											>
												{" "}
												- {titleCase(item)}
											</Link>
										</li>
									);
								})}
							</ul> */}
								<div className="text-xl p-4 rounded-md  text-center  text-blue-500 mb-4">
									{data?.distinct?.map((item) => {
										return (
											<Link
												to={`/faq/${slugify(item, {
													lower: false,
												})}`}
											>
												<div className="mb-6 p-4 rounded-lg border-2 outline outline-offset-[4px] hover:outline-offset-[0px]  outline-slate-300/10 hover:outline-slate-800/40 duration-100 ">
													{" "}
													{titleCase(item)}
												</div>
											</Link>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</section>
			) : null}
		</>
	);
};

// export const query = graphql`
// 	query MyQuery($RelatedService: String) {
// 		allFaqsCsv(
// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
// 		) {
// 			edges {
// 				node {
// 					Related_Service
// 				}
// 			}
// 		}
// 	}
// `;

export default RelatedQA;
