import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Defaults/Layout";

import Carousel from "../components/includes/services/carousel";
import Cta from "../components/CTA/CTAMain";
import FirstFive from "../components/includes/localServices/who_we_are";
import SecondFour from "../components/includes/localServices/cta_four";
import LastOne from "../components/includes/localServices/cta_one";
import RelatedService from "../components/includes/localServices/relatedServices";

import RelatedQA from "../components/includes/relatedQA";
import RelatedArea from "../components/includes/relatedArea";
import WhereWeAre from "../components/includes/where_we_are";
import Seo from "../components/seo";

const localService = ({ pageContext, data }) => {
	const { group, local_government_area, related_service, name, phone_number } =
		pageContext;
	const defaultNum = phone_number
		? phone_number
		: data?.site?.siteMetadata.phoneNum;

	const data_list = group.edges;
	const firstFive = data_list.slice(0, 5);
	const secondFour = data_list.slice(5, 9);
	const lastOne = data_list.slice(9, 10);
	const { images } = data;

	const { allImages } = images;

	// const last2 = data_list.slice(10, 15);

	return (
		<>
			<Layout phone_number={defaultNum}>
				{/* <Carousel /> */}
				<Carousel
					allImages={allImages[0]}
					related_service={related_service}
					area={name}
				/>

				<Cta background={true} phone_number={defaultNum} />
				{data?.relatedServices ? (
					<RelatedService area={name} data={data.relatedServices} />
				) : null}

				<div className="mt-6 mx-auto  max-w-screen-xl items-center mb-10 justify-between px-4">
					<h1 className="text-4xl text-blue-500 text-center my-20">
						URGENTLY REQUIRE OUR SERVICES?? CALL US NOW ON{" "}
						<a className=" text-gray-600 underline" href={`tel:+${defaultNum}`}>
							{defaultNum}
						</a>
					</h1>
				</div>
				<FirstFive data={firstFive} />

				<Cta
					phone_number={defaultNum}
					Heading={`Contact Our Local ${related_service} Specialist in ${name}`}
					subHeading={`Contact Us Now for a Free Quote`}
					callNow={true}
				/>
				<WhereWeAre
					heading={`Where We Cover in ${name}, ${local_government_area}`}
					area={`${name},${local_government_area}`}
				/>
				<SecondFour data={secondFour} />
				{lastOne && lastOne.length > 0 ? <LastOne data={lastOne} /> : null}
				<RelatedQA RelatedService={related_service} data={data?.relatedQA} />
				<RelatedArea
					RelatedService={related_service}
					area={name}
					local_government_area={local_government_area}
					data={data?.relatedAreas}
				/>
			</Layout>
		</>
	);
};

export const Head = ({
	pageContext: { local_government_area, related_service, name },
	data: {
		site: {
			siteMetadata: { title, phoneNum },
		},
	},
}) => {
	const description = `Looking for ${related_service} in ${name}, ${local_government_area}? Call ${title} now on ${phoneNum} for a free quote.`;
	return (
		<Seo title={`${related_service} in ${name}`} description={description} />
	);
};

export const query = graphql`
	query LocalServicePageQuery(
		$related_service: String
		$local_government_area: String
		$name: String
	) {
		images: allFile(
			sort: { modifiedTime: DESC }
			filter: {
				extension: { regex: "/(jpg)|(jpeg)|(png)/" }
				relativeDirectory: { eq: $related_service }
			}
		) {
			allImages: nodes {
				dir
				absolutePath
				base
				extension
				relativePath
				relativeDirectory
				root
				sourceInstanceName
				ext
				publicURL
				prettySize
				name
				childImageSharp {
					gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
				}
			}
		}
		relatedQA: allFaqsCsv(
			filter: { Related_Service: { eq: $related_service } }
		) {
			distinct(field: { Search: SELECT })
		}
		relatedAreas: allBranchesCsv(
			filter: {
				local_government_area: { eq: $local_government_area }
				name: { ne: $name }
				type: { nin: ["Suburban Area", "Village", "Other Settlement"] }
			}
		) {
			distinct(field: { name: SELECT })
		}
		relatedServices: allBranchesCsv(
			filter: {
				local_government_area: { eq: $local_government_area }
				name: { eq: $name }
				related_service: { ne: $related_service }
			}
		) {
			group(field: { related_service: SELECT }, limit: 1) {
				edges {
					node {
						related_service
						name
					}
				}
			}
		}

		site {
			siteMetadata {
				title
				phoneNum
			}
		}
	}
`;

export default localService;
