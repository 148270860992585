import React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";

var slugify = require("slugify");

const RelatedAreaService = ({ data, area }) => {
	const { site } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					mainService
				}
			}
		}
	`);
	return (
		<>
			<section className="mt-20 mx-auto  max-w-screen-xl items-center mb-20   justify-between px-4">
				<h3 className="text-3xl mb-10  text-blue-500 text-center pb-4  border-b-2">
					Our {site.siteMetadata.mainService} Services in {area}
				</h3>

				<div className="grid grid-cols-2 text-center md:grid-cols-4 gap-6 divide divide-y  p-6 max-h-96 overflow-auto">
					{data.group && data.group.length > 0
						? data.group?.map((item, index) => {
								var RelatedService = item.edges[0].node.related_service;
								return (
									<Link
										to={`/${slugify(RelatedService, {
											lower: true,
										})}/${slugify(area, { lower: true })}`}
										className={
											"col-span-1   md:col-span-1  shadow  rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300"
										}
									>
										<p className="tracking-wide ">
											{area ? `${RelatedService} in ${area}` : RelatedService}
										</p>
									</Link>
								);
						  })
						: null}
				</div>
			</section>
		</>
	);
};

// export const query = graphql`
// 	query MyQuery($RelatedService: String) {
// 		allFaqsCsv(
// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
// 		) {
// 			edges {
// 				node {
// 					Related_Service
// 				}
// 			}
// 		}
// 	}
// `;

export default RelatedAreaService;
