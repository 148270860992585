import React from "react";
import { Link } from "gatsby";
var slugify = require("slugify");

function titleCase(str) {
	var splitStr = str.toLowerCase().split(" ");
	for (var i = 0; i < splitStr.length; i++) {
		// You do not need to check if i is larger than splitStr length, as your for does that for you
		// Assign it back to the array
		splitStr[i] =
			splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
	}
	// Directly return the joined string
	return splitStr.join(" ");
}

const RelatedQA = ({ RelatedService, area, data, local_government_area }) => {
	// const datas = useStaticQuery(graphql`
	// 	query MyQuery($RelatedService: String) {
	// 		allFaqsCsv(
	// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
	// 		) {
	// 			distinct(field: { Search: SELECT })
	// 		}
	// 	}
	// `);
	return (
		<>
			<section className="mt-20 mx-auto  max-w-screen-xl items-center mb-20   justify-between px-4">
				<h3 className="text-3xl mb-10  text-blue-500 text-center pb-4  border-b-2">
					{titleCase(RelatedService) +
						" in " +
						titleCase(local_government_area)}
				</h3>

				<div className="grid grid-cols-1 text-center md:grid-cols-4 gap-6 divide divide-y  p-6 max-h-96 overflow-auto">
					<Link
						to={`/${slugify(local_government_area, { lower: true })}`}
						className={
							"col-span-1   md:col-span-4 shadow  rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300"
						}
					>
						<p className=" text-gray-500  tracking-wide ">
							{titleCase(local_government_area)}
						</p>
					</Link>
					{data.distinct && data.distinct.length > 0
						? data?.distinct?.map((item, index) => {
								return (
									<Link
										to={`/${slugify(RelatedService, {
											lower: true,
										})}/${slugify(item, { lower: true })}`}
										className={
											"col-span-1   md:col-span-2 shadow  rounded p-6 outline outline-[3px] hover:outline-[3px] outline-blue-500/10 hover:outline-blue-500/60 outline-offset-[5px] hover:outline-offset-0 duration-300"
										}
									>
										<p className=" text-gray-500  tracking-wide ">
											{titleCase(item)}
										</p>
									</Link>
								);
						  })
						: null}
				</div>
			</section>
		</>
	);
};

// export const query = graphql`
// 	query MyQuery($RelatedService: String) {
// 		allFaqsCsv(
// 			filter: { Related_Service: { eq: $RelatedService, ne: "" } }
// 		) {
// 			edges {
// 				node {
// 					Related_Service
// 				}
// 			}
// 		}
// 	}
// `;

export default RelatedQA;
